/* You can add global styles to this file, and also import other style files */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.container-search {
    height: 50px!important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

.main-container {
    width: 100%;
    height: 100%;
}

.required {
    color: #FF4444;
}

.table td {
    padding: .2rem!important;
    vertical-align: middle!important;
}

.edit {
    color: #e0a800;
}

.success {
    color: #218838;
}

.info {
    color: #138496;
}

.item-list-actions {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    top: 0;
    right: 100%;
    background-color: #d7dde4;
    border-radius: 3px;
    min-width: 60px!important;
}

.item-list .item-col.item-col-actions-dropdown-teacher {
    -ms-flex-preferred-size: 195px;
    flex-basis: 195px;
    text-align: center;
    padding-left: 0 !important;
}

.item-list .item-col.item-col-actions-dropdown-3 {
    -ms-flex-preferred-size: 130px;
    flex-basis: 90px;
    text-align: center;
    padding-left: 0 !important;
}

.box-search {
    background-color: #d7dde4;
    left: 240px;
    right: 0;
    -webkit-transition: left 0.3s ease;
    transition: left 0.3s ease;
    z-index: 10;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 10px;
    padding: 10px 20px;
}

.box-search .box-block-search {
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.box-search .box-block-search .input-box {
    position: relative;
    color: #7e8e9f;
}

.box-search .box-block {
    position: relative;
}

.box-search .box-block label {
    position: absolute;
    pointer-events: none;
    display: block;
}

.box-search .box-block-search .input-box i {
    position: absolute;
    pointer-events: none;
    display: block;
    height: 40px;
    line-height: 40px;
    left: 0;
}

.box-search .box-block-search .input-box input {
    background-color: transparent;
    border: none;
    padding-left: 25px;
    height: 40px;
    width: 100%;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-left: -200;
    height: 300px;
    margin-top: -150px;
    border: 1 solid #808080;
    padding: 5px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-r-10 {
    margin-right: 10px;
}

.items-list-page .item-list .item-col.item-col-item {
    text-align: left;
}

.items-list-page .item-list .item-col.item-col-header {
    text-align: center
}

.form-group .control-label i {
    font-size: 10px;
}

@media (max-width: 767.98px) {
    .container-search .container-block {
        padding: 5px;
    }
}